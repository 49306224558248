<template>
  <div>
    <center>
      <v-card-text>
        Tap this location icon to see where you are in Noble Park:
      </v-card-text>

      <v-btn
        :title="
          !geoLocateValue
            ? 'Click here to see your location on the map'
            : 'You have already enabled location on the map'
        "
        :disabled="geoLocateValue"
        color="primary"
        depressed
        :loading="isReady !== true"
        fab
        xicon
        xoutlined
        xplain
        rounded
        large
        @click="setTracking()"
      >
        <v-icon x-large>mdi-crosshairs-gps</v-icon>

        <div style="height: 0;width: 0;overflow:visible">
          <div
            style='    height: 0px;width: 0px;margin-top: 9px;margin-left: -7px;rotate: "1";transform: rotate(-12deg);'
          >
            <v-icon
              color="#515151"
              style="  -webkit-text-stroke-width: 1.5px; -webkit-text-stroke-color: white;"
              x-large
              >mdi-cursor-pointer</v-icon
            >
          </div>
        </div>
      </v-btn>
      <maptivateInfoButton
        v-show="false && geoLocateButtonFound"
        width="340px"
        color="primary"
        icon="mdi-crosshairs-gps"
        :title="
          !geoLocateValue
            ? 'Click here to see your location on the map'
            : 'Click here to hide your location on the map'
        "
        :clickfunction="
          () => {
            setTracking();
          }
        "
      />
      <br />
      <i> {{ geoLocateValue ? "You have enabled location on the map" : "" }}</i
      ><br />

      <i> * Location data not collected</i><br />
      <i v-show="geoLocateUnavailable">
        *Geolocation is blocked or unavailable
      </i>
    </center>
  </div>
</template>

<script>
import maptivateInfoButton from "../ui/maptivateInfoButton";

export default {
  name: "ToggleGeoButton",
  components: {
    maptivateInfoButton
  },
  data() {
    return {
      geoLocateCheckTimeout: null,
      geoLocateWatchState: "",
      geoLocateValue: null,
      geoLocateButtonFound: true,
      geoLocateUnavailable: false
    };
  },

  props: {
    dialog: Boolean,
    isReady: Boolean
  },
  methods: {
    setTracking() {
      window.mvAnalyticEvent("ui", "geolocateClicked", "about");

      //console.log(window.mapboxgl);
      //console.log(window.thismap._controls[3]._geolocateButton.click());

      let [control] = window?.thismap?._controls.filter(
        control => control?._geolocateButton
      );

      if (control && control._geolocateButton) {
        //console.log(control);

        try {
          control?._geolocateButton.click();
        } catch (error) {
          //do nothing;
        }

        this.checkGeoLocateStatus();
        setTimeout(() => {
          //this.checkGeoLocateStatus();
        }, 100);
      }
    },
    checkGeoLocateStatus() {
      try {
        this.geoLocateValue = false;
        let geoLocateUnavailable = false;
        if (window?.thismap?._controls) {
          let [control] = window?.thismap?._controls.filter(
            control => control?._geolocateButton
          );

          if (control) {
            this.geoLocateButtonFound = true;
            //console.log(control);
          } else {
            this.geoLocateButtonFound = false;
          }

          if (control?._watchState) {
            //console.log("found ws");
            this.geoLocateWatchState = control._watchState;

            if (
              control &&
              control._watchState &&
              control._watchState === "OFF"
            ) {
              this.geoLocateValue = false;
            } else {
              this.geoLocateValue = true;
            }
          }

          if (control?._geolocateButton?.ariaLabel) {
            if (
              control._geolocateButton.ariaLabel === "Location not available"
            ) {
              geoLocateUnavailable = true;
            } else {
              geoLocateUnavailable = false;
            }
          }

          this.geoLocateUnavailable = geoLocateUnavailable;
        }
      } catch (error) {
        //console.log("error");
        //do nothing;
      }
    }
  },
  mounted() {
    this.geoLocateValue = false;
    this.geoLocateCheckTimeout = setInterval(() => {
      this.geoLocateValue = false;

      if (this.dialog == true) {
        this.checkGeoLocateStatus();
      }
    }, 300);
  }
};
</script>
<style scoped>
i {
  font-size: 0.875rem;
  //color: red;
}
</style>
