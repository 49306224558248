import { mapState } from "vuex";

export default {
  data: () => ({
    //layerState: false
  }),

  computed: {
    ...mapState({
      //global:
      appSettings: state => state.global.appSettings
    })
  },

  methods: {
    addTrainLine2(options) {
      try {
        let { map } = options;
        let url = "./assets/20120713_211212_DSC_9856-5.jpg";
        let layerId = "temp20120713_211212_DSC_9856";

        map.loadImage(url, function(error, image) {
          if (error) throw error;

          // Specify the coordinates of the image's corners
          var topLeft = [145.17145384200472, -37.948567249277865]; // [longitude, latitude] of top left corner
          var topRight = [145.20808676056924, -37.97466285903703]; // [longitude, latitude] of top right corner
          var bottomRight = [145.17475234390957, -37.98659594889682]; // [longitude, latitude] of bottom right corner
          var bottomLeft = [145.14577459786824, -37.96054774993553]; // [longitude, latitude] of bottom left corner

          // Add the image to the map
          map.addImage(layerId, image);

          // Add a layer to display the image
          map.addLayer(
            {
              id: layerId,
              type: "raster",
              source: {
                type: "image",
                url: url,
                coordinates: [topLeft, topRight, bottomRight, bottomLeft]
              },
              paint: {
                "raster-opacity": 0.1
              }
            },
            "tunnel-path-trail"
          );
        });
      } catch (error) {
        //("error 2 xxx", { error });
      }
    },
    addTrainLine(options) {
      /*
      let layers = this.map.getStyle().layers;
      for (const layer of layers) {
        let { id: layerId } = layer;

        (layerId);
      }
*/
      try {
        let { map } = options;
        let url = "./assets/grid.png";

        map.loadImage(url, function(error, image) {
          if (error) throw error;

          // Specify the coordinates of the image's corners
          var topLeft = [145.16195386523253, -37.958194550282805]; // [longitude, latitude] of top left corner
          var topRight = [145.19603154158665, -37.957101698652096]; // [longitude, latitude] of top right corner
          var bottomRight = [145.19717861624798, -37.98317496382216]; // [longitude, latitude] of bottom right corner
          var bottomLeft = [145.16339788852446, -37.982365512986036]; // [longitude, latitude] of bottom left corner

          // Add the image to the map
          map.addImage("custom-image", image);

          // Add a layer to display the image
          map.addLayer(
            {
              id: "custom-image-layer",
              type: "raster",
              source: {
                type: "image",
                url: url,
                coordinates: [topLeft, topRight, bottomRight, bottomLeft]
              },
              paint: {
                "raster-opacity": 0.3
              }
            },
            "tunnel-path-trail"
          );
        });
      } catch (error) {
        //console.error("error 2 xxx", { error });
      }
    },
    add3dTrainLine(options) {
      //("addTrainLine", options);

      let { map } = options;

      let data = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              base_height: 10,
              height: 99
            },
            geometry: {
              coordinates: [
                [
                  [145.1982784233483, -37.97144535713388],
                  [145.19948475105707, -37.970624192790794],
                  [145.21404482849562, -37.98300844946474],
                  [145.21231728367985, -37.984456398247],
                  [145.1981961684541, -37.97146696860817]
                ]
              ],
              type: "Polygon"
            }
          },
          {
            type: "Feature",
            properties: { base_height: 0, height: 10 },
            geometry: {
              coordinates: [
                [
                  [145.19913725881054, -37.97084712367956],
                  [145.21341795203472, -37.98356711289746],
                  [145.21290899651177, -37.98408624930399],
                  [145.1986582418491, -37.97117754089569],
                  [145.1991671973721, -37.970917927493964]
                ]
              ],
              type: "Polygon"
            }
          }
        ]
      };

      map.addSource("train3dData", {
        type: "geojson",
        data: data
      });

      map.addLayer({
        id: "extruded-polygon",
        type: "fill-extrusion",
        source: "train3dData",
        paint: {
          "fill-extrusion-color": "blue", // Color of the extrusion
          "fill-extrusion-height": ["get", "height"],
          "fill-extrusion-base": ["get", "base_height"],
          "fill-extrusion-opacity": 0.9 // Opacity of the extrusion
        }
      });
    }
  },
  created() {},
  destroyed() {},
  mounted() {},

  watch: {}
};
