import _ from "lodash";
import { mapState } from "vuex";

export default {
  data: () => ({
    //layerState: false
  }),

  computed: {
    ...mapState({
      //global:
      appSettings: state => state.global.appSettings,
      mapStyleExtraFilters: state => state.filters.mapStyleExtraFilters,

      currentCountryNotEnglish: state =>
        state.translator.currentCountryNotEnglish,
      translationLanguageCode: state =>
        state.translator.translationLanguageCode,
      translatedList: state => state.translator.translatedList
    }),
    fields() {
      let defaultTooltipLabelField = null;
      defaultTooltipLabelField = this?.appSettings?.defaultTooltipLabelField;
      let places = this?.appSettings?.layerDefaults?.places?.labelField;
      let signage = this?.appSettings?.layerDefaults?.signage?.labelField;

      return {
        places: {
          label: places,
          tooltip: defaultTooltipLabelField ? defaultTooltipLabelField : places
        },
        signage: {
          label: signage,
          tooltip: defaultTooltipLabelField ? defaultTooltipLabelField : signage
        }
      };
    }
  },

  methods: {
    translateGeoJsonLables_debounced: _.debounce(function() {
      this.translateGeoJsonLables();
    }, 1500),

    translateGeoJsonLables() {
      try {
        let dataSouces = ["signage", "places"];
        dataSouces.forEach(source => {
          var labelField = null,
            tooltipField = null;

          if (this.fields && this.fields[source]) {
            if (this.fields[source].label) {
              labelField = this.fields[source].label;
            }
            if (this.fields[source].tooltip) {
              tooltipField = this.fields[source].tooltip;
            }
            if (labelField && tooltipField && labelField == tooltipField) {
              tooltipField = null;
            }
          }

          let thisSourceData = this.map.getSource(source)._data;

          thisSourceData.features.forEach(feature => {
            var search = [labelField, tooltipField];

            search.forEach(fieldName => {
              if (fieldName) {
                if (feature.properties[fieldName]) {
                  let currentValue = feature.properties[fieldName];

                  //make backup of english version;

                  if (!feature.properties.englishClone) {
                    feature.properties.englishClone = {};
                  }
                  if (!feature.properties.englishClone[fieldName]) {
                    feature.properties.englishClone[fieldName] =
                      feature.properties[fieldName];
                  }
                  //make backup of english version;

                  if (
                    feature.properties?.englishClone &&
                    feature.properties?.englishClone[fieldName]
                  ) {
                    let englishWord =
                      feature.properties?.englishClone[fieldName];

                    if (englishWord && englishWord !== currentValue) {
                      //if value set to english;
                      feature.properties[fieldName] = englishWord;
                      currentValue = feature.properties[fieldName];
                    }

                    if (
                      englishWord &&
                      this.currentCountryNotEnglish &&
                      this.translatedList &&
                      this.translationLanguageCode &&
                      this.translatedList[this.translationLanguageCode] &&
                      this.translatedList[this.translationLanguageCode][
                        englishWord
                      ] &&
                      this.translatedList[this.translationLanguageCode][
                        englishWord
                      ] != currentValue
                    ) {
                      //if not english, and there is a lookup, but current value is not the lookup;

                      feature.properties[fieldName] = this.translatedList[
                        this.translationLanguageCode
                      ][englishWord];
                    }
                  }
                }
              }
            });
          });

          this.map.getSource(source).setData(thisSourceData);
        });
      } catch (error) {
        //do nothing;
      }
    },

    updatefilters(filters) {
      let mapStyleKeys = _.keys(this.mapStyleExtraFilters);
      let mapStyleFilters = filters.filter(row => mapStyleKeys.includes(row));
      filters = filters.filter(row => !mapStyleKeys.includes(row));

      mapStyleKeys.forEach(layer => {
        let layerId = layer.replace("basemapStyle_", "");
        let show = false;
        let hasLinkedLayer = this.mapStyleExtraFilters[layer]
          ?.linkedToMakerLayer;

        if (mapStyleFilters.includes(layer)) {
          show = true;
        }

        if (filters.includes(hasLinkedLayer)) {
          show = true;
        }

        if (show === true) {
          //show layer;
          this.map.setLayoutProperty(layerId, "visibility", "visible");
        } else {
          //hide layer;
          this.map.setLayoutProperty(layerId, "visibility", "none");
        }
      });

      //markerLayerFilter;
      let dataSouces = _.values(this.filterableDataSouceIds);
      dataSouces.forEach(source => {
        let thisSourceData = this.map.getSource(source)._data;
        thisSourceData.features.forEach(feature => {
          if (filters.includes(feature.properties.section_slug)) {
            feature.properties.hide = false;

            if (!feature.properties.isGeomLinePolyLayer) {
              feature.geometry.coordinates = [
                feature.properties.Latitude,
                feature.properties.Longitude
              ];
            }
          } else {
            feature.properties.hide = true;
            if (!feature.properties.isGeomLinePolyLayer) {
              feature.geometry.coordinates = [0, 0];
            }
          }

          if (
            feature.properties.isGeomLinePolyLayer &&
            feature.properties.section_slug == "Art_zoom1"
          ) {
            feature.properties.hide = false;
          }

          if (feature.properties.isGeomLinePolyLayer) {
            //(feature.properties);
          }
        });

        this.map.getSource(source).setData(thisSourceData);
      });
    }
  },
  created() {},
  destroyed() {},
  mounted() {},

  watch: {
    currentCountryNotEnglish(value) {
      if (!value) {
        this.translateGeoJsonLables();
      }
    },
    translationLanguageCode() {
      this.translateGeoJsonLables();
      this.translateGeoJsonLables_debounced();
      setTimeout(() => {
        this.translateGeoJsonLables_debounced();
      }, 700);
    },
    translatedList() {
      this.translateGeoJsonLables_debounced();

      setTimeout(() => {
        this.translateGeoJsonLables_debounced();
      }, 700);
    }
  }
};
