<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="
        $vuetify.breakpoint.xsOnly
          ? '95%'
          : $vuetify.breakpoint.smAndDown
          ? 550
          : 650
      "
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class=" " @click="scrollToSiteDelay()">
          FAQ
        </span>
      </template>

      <v-card>
        <v-card-title
          v-if="true"
          :style="{
            color: 'black',
            background: appSettings.menuBarColor
          }"
          class="  xlighten-2 secondary--text text-center justify-center siteHeadingText"
        >
          FAQs
        </v-card-title>

        <v-row no-gutters class="secondary--text " style="">
          <v-col
            cols="12"
            md="12"
            class="pt-3 px-4 imageWrapper"
            id="HelpActWrapper"
            @scroll="onScroll"
          >
            <center id="topOfHelpSection">
              <v-card-title id="helpHeading_nav" class="justify-center"
                >How do I navigate around the map on my phone?
              </v-card-title>

              <v-card-text>
                Use two fingers to pinch and zoom in and out. <br />
                Use one finger to navigate around the map.
              </v-card-text>
              <br />
              <br />
              <v-divider></v-divider>
              <v-card-title id="helpHeading_language" class="justify-center"
                >How do I translate the map into another language?
              </v-card-title>
              <v-card-text
                >Click on the translation button in the top left of the screen
                to see a list of languages to choose from.
              </v-card-text>
              <img
                style="max-width: 250px;"
                src="/assets/help/Welcome_translate.png"
              />

              <br />
              <br />
              <br />
              <br />

              <v-divider></v-divider>
              <v-card-title id="helpHeading_gps" class="justify-center"
                >How do I find my location in Noble Park?
              </v-card-title>
              <v-card-text>
                Tap the location button on the left-hand side. If prompted, you
                may need to allow location services on your phone. This
                information is not collected nor retained by The City of Greater
                Dandenong or Maptivate.
              </v-card-text>

              <img src="/assets/help/FAQ_find_location.png" />

              <br />
              <br />
              <br />

              <v-divider></v-divider>
              <v-card-title id="helpHeading_search" class="justify-center"
                >How do I search for a place?
              </v-card-title>
              <v-card-text
                >Open the menu by clicking the three white lines in the top
                corner of the map then find the light grey ‘List Filter’ search
                bar and type in what you’re looking for:
              </v-card-text>
              <img src="/assets/help/FAQ_Search.png" />
              <br />
              <br />
              <br />
              <br />

              <v-divider></v-divider>

              <v-card-title id="helpHeading_legend" class="justify-center"
                >What do the symbols on the map mean and how can I view the map
                legend?
              </v-card-title>
              <v-card-text
                >Click on the map symbol in the bottom right of the screen to
                bring up the legend.
              </v-card-text>
              <img src="/assets/help/FAQ_Legend1.png" />
            </center>

            <br />
            <br />
            <br />
            <br />

            <v-divider></v-divider>

            <div>
              <v-card-title>
                How do I check my location settings on an iPhone?
              </v-card-title>

              <ol>
                <li>
                  <strong
                    >Ensure Location Services are Enabled for Safari:</strong
                  >
                </li>

                <ul class="primaryList">
                  <li class="ql-indent-1">
                    <span>Open the </span><strong>Settings</strong
                    ><span> app on your iPhone.</span>
                  </li>
                  <li class="ql-indent-1">
                    <span>Scroll down and tap on </span><strong>Privacy</strong
                    ><span>.</span>
                  </li>
                  <li class="ql-indent-1">
                    <span>Tap on </span><strong>Location Services</strong
                    ><span>.</span>
                  </li>
                  <li class="ql-indent-1">
                    <span>Make sure </span><strong>Location Services</strong
                    ><span> is turned on.</span>
                  </li>
                  <li class="ql-indent-1">
                    <span>Scroll down and find </span
                    ><strong>Safari Websites</strong
                    ><span> in the list of apps.</span>
                  </li>
                  <li class="ql-indent-1">
                    <span>Tap on </span><strong>Safari Websites</strong
                    ><span> and select </span
                    ><strong>While Using the App</strong><span> or </span
                    ><strong>Ask Next Time</strong><span>.</span>
                  </li>
                </ul>

                <li>
                  <strong>Grant Location Access to Your Website:</strong>

                  <ul class="primaryList">
                    <li class="ql-indent-1">
                      <span>Open </span><strong>Safari</strong
                      ><span> and navigate to your website.</span>
                    </li>
                    <li class="ql-indent-1">
                      <span
                        >When prompted with a message like "Yourwebsite.com
                        wants touse your location," select </span
                      ><strong>Allow</strong><span>.</span>
                    </li>
                    <li class="ql-indent-1">
                      <span
                        >If you don't see the prompt, you can manually check
                        thewebsite settings:</span
                      >

                      <ul class="secondaryList">
                        <li class="ql-indent-2">
                          <span>Tap the </span><strong>aA</strong
                          ><span>
                            icon in the top-left corner of the address
                            bar.</span
                          >
                        </li>
                        <li class="ql-indent-2">
                          <span>Tap on </span><strong>Website Settings</strong
                          ><span>.</span>
                        </li>
                        <li class="ql-indent-2">
                          <span>Under </span><strong>Location</strong
                          ><span>, select </span><strong>Allow</strong
                          ><span>.</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Check Permissions in Settings (if necessary):</strong>

                  <p>
                    If you have previously denied location permissions, you
                    mayneed to adjust the settings:
                  </p>

                  <ul class="primaryList">
                    <li>
                      <span>Open the </span><strong>Settings</strong
                      ><span> app on your iPhone.</span>
                    </li>
                    <li>
                      <span>Scroll down and tap on </span><strong>Safari</strong
                      ><span>.</span>
                    </li>
                    <li>
                      <span>Tap on </span><strong>Location</strong
                      ><span>.</span>
                    </li>
                    <li>
                      <span>Make sure it is set to </span><strong>Allow</strong
                      ><span> or </span><strong>Ask</strong><span>.</span>
                    </li>
                  </ul>
                </li>
              </ol>

              <br />
              <br />
              <br />
              <br />

              <v-divider></v-divider>

              <v-card-title>
                How do I check my location settings on an AndroidPhone?
              </v-card-title>

              <p>
                <strong>Open the Chrome Browser:</strong>
              </p>
              <ul>
                <li>
                  <span>Tap on the Chrome app icon to open the browser.</span>
                </li>
              </ul>
              <p>
                <strong>Access Chrome Settings:</strong>
              </p>
              <ul>
                <li>
                  <span
                    >Tap the three vertical dots (menu icon) in the
                    upper-rightcorner of the browser window.</span
                  >
                </li>
                <li>
                  <span>Select "Settings" from the drop-down menu.</span>
                </li>
              </ul>
              <p>
                <strong>Navigate to Site Settings:</strong>
              </p>
              <ul>
                <li>
                  <span>Scroll down and tap on "Site settings."</span>
                </li>
              </ul>
              <p>
                <strong>Open Location Settings:</strong>
              </p>
              <ul>
                <li>
                  <span>In the "Site settings" menu, tap on "Location."</span>
                </li>
              </ul>
              <p>
                <strong>Enable Location Access:</strong>
              </p>
              <ul>
                <li>
                  <span
                    >Ensure the switch at the top is turned on (it should
                    beblue). This enables location services for Chrome.</span
                  >
                </li>
              </ul>
              <p>
                <strong>Manage Individual Site Permissions:</strong>
              </p>
              <ul>
                <li>
                  <span
                    >Below the switch, you can see a list of websites
                    withlocation permissions. You can manage individual
                    sitepermissions by tapping on a site and selecting
                    either"Allow" or "Block."</span
                  >
                </li>
              </ul>
              <p>
                <strong>Verify Device Location Settings:</strong>
              </p>
              <ul>
                <li>
                  <span>Go to your phone's main "Settings" app.</span>
                </li>
                <li>
                  <span
                    >Tap on "Location" (this might be under "Connections"
                    or"Privacy" depending on your device).</span
                  >
                </li>
                <li>
                  <span>Ensure the location services switch is turned on.</span>
                </li>
                <li>
                  <span
                    >Tap on "App permissions" or "App access to location"
                    andmake sure Chrome is listed and set to "Allow."</span
                  >
                </li>
              </ul>

              <br />
              <br />
              <br />
              <br />

              <v-divider></v-divider>

              <v-card-title>
                How do I check my location settings on an AndroidPhone?
              </v-card-title>

              <font>
                <strong>Step 1: Open Settings</strong>
              </font>
              <ul>
                <li>
                  <span>Unlock your Samsung phone and go to the </span
                  ><strong>Settings</strong
                  ><span
                    >app. You can find it in your app drawer or by swiping
                    downfrom the top of the screen and tapping the gear
                    icon.</span
                  >
                </li>
              </ul>
              <font>
                <strong>Step 2: Access Location Settings</strong>
              </font>
              <ul>
                <li>
                  <span>Scroll down and tap on </span><strong>Location</strong
                  ><span>. This option is usually found under the </span
                  ><strong>Connections</strong><span> or </span
                  ><strong>Biometrics and security</strong
                  ><span
                    >section, depending on your device model and
                    Androidversion.</span
                  >
                </li>
              </ul>
              <font>
                <strong>Step 3: Enable Location Services</strong>
              </font>
              <ul>
                <li>
                  <span>Ensure that the </span><strong>Location</strong
                  ><span
                    >toggle is turned on. If it's off, tap the toggle to turn
                    iton.</span
                  >
                </li>
              </ul>
              <font>
                <strong>Step 4: Manage App Permissions</strong>
              </font>
              <ul>
                <li>
                  <span>Tap on </span><strong>App permissions</strong
                  ><span> (or </span><strong>App location permissions</strong
                  ><span
                    >). This will show you a list of apps that have
                    requestedlocation access.</span
                  >
                </li>
              </ul>
              <font>
                <strong>Step 5: Find Your Browser</strong>
              </font>
              <ul>
                <li>
                  <span>Look for your browser app in the list (e.g., </span
                  ><strong>Chrome</strong><span>, </span
                  ><strong>Samsung Internet</strong><span>, </span
                  ><strong>Firefox</strong><span>, etc.) and tap on it.</span>
                </li>
              </ul>
              <font>
                <strong>Step 6: Adjust Location Permissions</strong>
              </font>
              <ul>
                <li>
                  <span
                    >Choose one of the following options for
                    locationaccess:</span
                  >
                </li>
              </ul>
              <ul>
                <li class="ql-indent-1">
                  <strong>Allow all the time</strong
                  ><span>: The app can access your location at any time.</span>
                </li>
                <li class="ql-indent-1">
                  <strong>Allow only while using the app</strong
                  ><span
                    >: The app can access your location only when you're
                    usingit.</span
                  >
                </li>
                <li class="ql-indent-1">
                  <strong>Ask every time</strong
                  ><span
                    >: The app will ask for permission each time it needs
                    toaccess your location.</span
                  >
                </li>
                <li class="ql-indent-1">
                  <strong>Deny</strong
                  ><span>: The app cannot access your location.</span>
                </li>
              </ul>
              <font>
                <strong>Step 7: Confirm and Exit</strong>
              </font>
              <ul>
                <li>
                  <span
                    >After selecting your preferred option, go back to the
                    homescreen or exit the Settings app.</span
                  >
                </li>
              </ul>
              <font>
                <strong>Step 8: Test Your Browser</strong>
              </font>
              <ul>
                <li>
                  <span
                    >Open your browser and navigate to a website that
                    requireslocation access to ensure the settings are
                    workingcorrectly.</span
                  >
                </li>
              </ul>
              <p><br /></p>
            </div>
          </v-col>
        </v-row>

        <v-card-actions>
          <transition name="fade" mode="out-in">
            <v-btn
              v-show="!atTop"
              class="v-fade"
              depressed
              dark
              :color="appSettings.dialogActionButtonColor"
              @click="scrollToSite(false)"
            >
              Top
            </v-btn>
          </transition>

          <v-spacer></v-spacer>

          <v-btn
            depressed
            dark
            :color="appSettings.dialogActionButtonColor"
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import { mapState } from "vuex";

export default {
  name: "HelpModal",
  components: {},
  data() {
    return {
      atTop: true,
      atBottom: false,
      dontShowAgain: false,
      dialog: false,
      title: "Help"
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  created() {
    eventBus.$on("loadHelpDialog", scrollTo => {
      this.dialog = true;

      setTimeout(() => {
        this.scrollToSite(scrollTo);
      }, 500);
    });
  },
  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      let atBottom = false;
      let atTop = false;
      if (scrollTop + clientHeight >= scrollHeight) {
        atBottom = true;
      }
      if (scrollTop < 20) {
        atTop = true;
      }

      this.atTop = atTop;
      this.atBottom = atBottom;
    },
    scrollToSiteDelay() {
      setTimeout(() => {
        this.scrollToSite();
      }, 100);
    },
    scrollToSite(newValue) {
      let element = `topOfHelpSection`;

      if (newValue) {
        element = `helpHeading_${newValue}`;
      }

      var AboutContentWrapper = document.getElementById("HelpActWrapper");
      var heading = document.getElementById(element);

      // Scroll the 'myDiv' to bring the 'heading' element into view
      AboutContentWrapper.scrollTop =
        heading.offsetTop - AboutContentWrapper.offsetTop;
    }
  }
};
</script>

<style>
#topOfHelpSection img {
  max-width: 80%;
}

.primaryList {
  list-style-type: disc;
}

.secondaryList {
  list-style-type: circle;
}
</style>
