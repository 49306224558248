<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="$vuetify.breakpoint.xsOnly ? '95%' : 500"
    >
      <template v-slot:activator="{ on, attrs }" v-show="showButton">
        <span
          v-bind="attrs"
          v-on="on"
          class=" "
          v-show="showButton"
          @click="scrollToSiteDelay()"
        >
          Welcome
        </span>
      </template>

      <v-card class="dialog px-3">
        <v-card-title
          v-if="true"
 
          class="  xlighten-2 secondary--text text-center justify-center siteHeadingText"
        >
          Welcome To Maptivate
        </v-card-title>

        <v-row>
          <v-col
            cols="12"
            id="AboutContentWrapper"
            class="contentWrapper"
            @scroll="onScroll"
          >
            <center>
              <div v-if="true">
                <v-card-text class="pb-3 pt-0">
                  Take a walk through Noble Park, enjoy the art installations,
                  stories from the past and discover many fascinating landmarks.
                </v-card-text>
                <v-card-text class="pb-3 pt-0">
                  You have the freedom to choose your own path or follow the
                  numbers to see all the sites.
                </v-card-text>

                <v-card-text class="pb-3 pt-0">
                  You can even download your own
                  <a
                    href="https://www.greaterdandenong.vic.gov.au/_flysystem/filerepo/A10970347"
                    target="_blank"
                  >
                    MAP for colouring in.
                  </a>
                  <br />
                  <a v-if="false"
                    href="https://www.greaterdandenong.vic.gov.au/our-street"
                    target="_blank"
                  >
                    More details here.</a
                  >
                </v-card-text>

                <v-card-text class="pb-3 pt-0">
                  Project is made possible with the support of the Noble Park
                  Revitalisation Board and funding from the Victorian Government
                </v-card-text>

                <div class="logoSet d-flex  flex-row flex-wrap justify-center">
                  <img src="/logos/logo1.png" />
                  <img src="/logos/logo3.png" />
                  <img src="/logos/logo2.png" />
                </div>

                <v-card-text>
                  Learn how to use the map here
                  <v-btn
                    x-small
                    depressed
                    dark
                    :color="appSettings.dialogActionButtonColor"
                    @click="nav_faq()"
                  >
                    FAQ</v-btn
                  >
                  <br />
                  <br />
                  Translate text into multiple languages here:

                  <img
                    style="max-width: 200px;"
                    src="/assets/help/Welcome_translate.png"
                  />
                  <ToggleGeoButton
                    :isReady="isReady"
                    :dialog="dialog"
                  ></ToggleGeoButton>

                  <br />
                  You can also use this button to change the location settings:
                </v-card-text>
                <v-img
                  style="max-width: 100px;"
                  src="/assets/help/Welcome_set_location.png"
                ></v-img>

                <intoVideo
                  v-if="false"
                  v-show="$vuetify.breakpoint.mdAndDown"
                ></intoVideo>
              </div>
            </center>
          </v-col>
        </v-row>

        <v-card-actions class="px-0 mt-3">
          <v-btn
            depressed
            dark
            :color="appSettings.dialogActionButtonColor"
            @click="nav_faq()"
          >
            FAQ
          </v-btn>
          <v-btn
            depressed
            dark
            :color="appSettings.dialogActionButtonColor"
            @click="nav_contactUs()"
          >
            Contact <font v-show="!$vuetify.breakpoint.xsOnly">Us</font>
          </v-btn>

          <v-spacer></v-spacer>
          <transition name="fade" mode="out-in">
            <v-btn
              depressed
              dark
              :color="appSettings.dialogActionButtonColor"
              v-show="$vuetify.breakpoint.mdAndDown && !atTop"
              class="v-fade"
              @click="scrollToSite(false)"
            >
              Top
            </v-btn>
          </transition>
          <v-spacer></v-spacer>

          <v-btn
            depressed
            dark
            :color="appSettings.dialogActionButtonColor"
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "./../../main";
import { mapState } from "vuex";
import ToggleGeoButton from "./../ui/ToggleGeoButton.vue";
import intoVideo from "./intoVideo.vue";

export default {
  name: "AboutModal",
  components: {
    ToggleGeoButton,
    intoVideo
  },
  data() {
    return {
      atTop: true,
      atBottom: false,
      dontShowAgain: false,
      dialog: false,
      title: "Welcome to Maptivate"
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      imageLog: state => state.markers.imageLog
    })
  },
  created() {
    eventBus.$on("loadAboutDialog", scrollTo => {
      this.dialog = true;

      setTimeout(() => {
        this.scrollToSite(scrollTo);
      }, 500);
    });
  },
  props: {
    isReady: Boolean,
    showButton: Boolean
  },
  methods: {
    nav_faq() {
      eventBus.$emit("loadHelpDialog");
    },
    nav_contactUs() {
      eventBus.$emit("loadContactUsPopup");
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      let atBottom = false;
      let atTop = false;
      if (scrollTop + clientHeight >= scrollHeight) {
        atBottom = true;
      }
      if (scrollTop < 20) {
        atTop = true;
      }

      this.atTop = atTop;
      this.atBottom = atBottom;
    },
    updateDontShowCheckbox() {
      localStorage.setItem("dontShowWelcome", this.dontShowAgain);
    },
    scrollToSiteDelay() {
      setTimeout(() => {
        this.scrollToSite();
      }, 100);
    },
    scrollToSite(newValue) {
      let element = `AboutContentWrapper`;

      if (newValue) {
        element = `aboutHeading_${newValue}`;
      }

      var AboutContentWrapper = document.getElementById("AboutContentWrapper");
      var heading = document.getElementById(element);

      // Scroll the 'myDiv' to bring the 'heading' element into view
      AboutContentWrapper.scrollTop =
        heading.offsetTop - AboutContentWrapper.offsetTop;
    }
  },
  mounted() {
    let bootOption = false;

    if (bootOption != true) {
      this.dialog = true;
    }
  }
};
</script>

<style>
.logoSet {
  width: 100%;
  padding-bottom: 20px;
}

.logoSet img {
  height: 40px;
  padding: 0 10px 5px 10px;
}

@media only screen and (max-width: 1024px) {
  .logoSet img {
    height: 40px;
  }
}

.imageWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.welcomeText img {
  display: block;
}

.mobileSizeDialog {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}
.contentWrapper {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

@media only screen and (max-width: 960px) {
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 98%;
  }
}

@media only screen and (max-width: 760px) {
  .contentWrapper,
  .introVideo {
    max-height: calc(100vh - 240px);
  }

  .imageWrapper {
    max-height: calc(100vh - 220px);
    overflow-y: auto;
  }

  .isApple .contentWrapper,
  .isApple .introVideo {
    max-height: calc(100vh - 260px);
  }

  .isApple .imageWrapper {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
  }
}

.v-fade {
  display: inherit !important; /* override v-show display: none */
  transition: opacity 0.5s;
}

.v-fade[style*="display: none;"] {
  opacity: 0;
  pointer-events: none; /* disable user interaction */
  user-select: none; /* disable user selection */
}
</style>
