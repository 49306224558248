<template>
  <div class="text-center" ref="myDiv">
    <v-dialog
      v-model="openContacts"
      :width="$vuetify.breakpoint.smAndDown ? '95%' : 400"
    >
      <template v-slot:activator="{ on, attrs }" v-if="false">
        <span v-bind="attrs" v-on="on">
          <font v-if="!$vuetify.breakpoint.xsOnly" class="secondary--text ">
          </font
          ><font v-if="!$vuetify.breakpoint.xsOnly">
            Contact us, Inform us about our history, Snap Send Solve
          </font>
        </span>
      </template>

      <v-card>
        <v-card-title
          :style="{
            background: appSettings.menuBarColor
          }"
          class="  xlighten-2 secondary--text text-center justify-center siteHeadingText"
          >Contact us
        </v-card-title>

        <center>
          <maptivateInfoButton
            butType="link"
            :width="`${divWidth - 20}px`"
            :color="appSettings.mediaButtonColor"
            :icon="'mdi-email'"
            title="Digital Tour Feedback welcome - click here"
            href="mailto:placemaking@cgd.vic.gov.au"
            target="_blank"
          />

          <v-card-text>
            Please send feedback to:<a
              href="mailto:placemaking@cgd.vic.gov.au"
              target="_blank"
            >
              placemaking@cgd.vic.gov.au</a
            >
          </v-card-text>

          <maptivateInfoButton
            butType="link"
            :width="`${divWidth - 20}px`"
            color="blue-grey"
            :icon="'mdi-open-in-new'"
            title="Click here To notify council of other issues with Snap Send Solve"
            href="https://me.snapsendsolve.com/snap?utm_source=snapsendsolve&utm_medium=website&utm_campaign=homepage&_=1709512373905&step=photos&_ga=2.188221420.574655753.1718935059-563654540.1718935058"
            target="_blank"
          />
        </center>

        <v-card-actions>
          <v-spacer></v-spacer>
          <a
            v-if="false"
            href="http://maptivate.com.au/"
            target="_blank"
            style="text-decoration: none!important;"
          >
            <v-btn dark :color="appSettings.dialogActionButtonColor" text>
              More Information
            </v-btn>
          </a>

          <v-btn
            v-if="false"
            dark
            :color="appSettings.dialogActionButtonColor"
            text
            @click="loadAbout()"
          >
            About Maptivate
          </v-btn>

          <v-btn
            depressed
            dark
            :color="appSettings.dialogActionButtonColor"
            @click="openContacts = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../../main";
import maptivateInfoButton from "../ui/maptivateInfoButton";

export default {
  name: "AcknowledgementModal",
  components: { maptivateInfoButton },
  data() {
    return {
      dontShowAgain: false,
      openContacts: false,
      divWidth: 0
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  methods: {
    updateDivWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.divWidth = this.$refs.myDiv.offsetWidth;
      } else {
        this.divWidth = 380;
      }

      //console.log(this.divWidth);
    }
  },
  created() {
    eventBus.$on("loadContactUsPopup", () => {
      this.openContacts = true;
      setTimeout(() => {
        this.updateDivWidth();
      }, 200);
    });
  },
  beforeDestroy() {
    //do something before destroying vue instance
    //eventBus.$off("loadContactUsPopup");
  },

  mounted() {
    this.updateDivWidth();
    // Optional: If the div's width might change due to window resizing or other reasons,
    // you can add an event listener to update the width dynamically.
    window.addEventListener("resize", this.updateDivWidth);
  }
};
</script>

<style>
@media only screen and (min-width: 960px) {
  .noOverflowOnWide {
    overflow: hidden;
  }

  .v-application div.bodyTextAck {
    margin-bottom: -18px !important;
    padding-top: 5px !important;
  }
}

@media only screen and (max-width: 959px) {
  .v-application div.bodyTextAck {
    padding-top: 0 !important;
  }

  .noOverflowOnWide {
    margin: 0 !important;
  }
}
</style>
