var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mvLegendWrapper",class:_vm.drawer ? 'open' : 'close',staticStyle:{"width":"0","height":"0","overflow":"visible"}},[_c('div',{staticClass:"mvLegend"},[_c('div',{staticClass:"mobileSet"},[(!_vm.showLegend)?_c('div',{staticClass:"mvLegendButtonWrapper"},[_c('v-btn',{staticClass:"ml-2 pa-0",attrs:{"title":"Show Legend","z-depth":"0","depressed":"","notsmall":"","color":!_vm.showLegend ? _vm.appSettings.filterLegendButtonColor : 'white'},on:{"click":function($event){_vm.showLegend = !_vm.showLegend}}},[(_vm.showLegend)?_c('v-icon',[_vm._v("mdi-close-circle ")]):_vm._e(),(!_vm.showLegend)?_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-map-legend ")]):_vm._e()],1)],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLegend),expression:"showLegend"}],staticClass:"legendListActWrapper",staticStyle:{"border-radius":"8px","overflow":"hidden"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":_vm.$vuetify.breakpoint.smAndDown ? 300 : 300}},[_c('v-toolbar',{attrs:{"color":"","dense":"","height":"35"}},[_c('v-toolbar-title',[_vm._v("Legend")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","title":"Close Legend"},on:{"click":function($event){_vm.showLegend = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-list',{staticClass:"legendListAct",attrs:{"dense":""}},[_vm._l((_vm.dynamiclegendOld),function(section,key,index){return [(
                  key && _vm.hideByLegendKey && !_vm.hideByLegendKey.includes(key)
                )?[_c('v-subheader',{directives:[{name:"show",rawName:"v-show",value:(section.items.length),expression:"section.items.length"}],key:("legend_heading_" + key),staticClass:"text-subtitle-1 font-weight-bold ml-11 mb-1 pt-1"},[_vm._v(" "+_vm._s(section.title)+" "),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
                      _vm.showLayerButtonForSlugIfHidden.includes(key) &&
                        !_vm.layers.includes(key)
                    ),expression:"\n                      showLayerButtonForSlugIfHidden.includes(key) &&\n                        !layers.includes(key)\n                    "}],staticClass:"ml-3",attrs:{"x-small":"","text":"","outlined":"","depressed":"","color":"error"},on:{"click":function($event){return _vm.showLayer(key)}}},[_vm._v(" show ")])],1),_vm._l((section.items),function(item,i){return [(
                      item &&
                        (!item.section_slug ||
                          !_vm.hideSectionSlugs.includes(item.section_slug))
                    )?_c('v-list-item',{key:("legend_list_" + key + "_item_" + i)},[_c('v-list-item-avatar',{attrs:{"tile":item.tile === true,"size":"30"}},[(
                          item &&
                            item.taxonomy != 'markersection' &&
                            item.featured_image_obj &&
                            item.featured_image_obj.thumb
                        )?[_c('img',{attrs:{"src":("" + (item.featured_image_obj.thumb))}})]:[_c('v-avatar',{style:(item.style),attrs:{"size":"30","color":item.color}})]],2),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-regular",domProps:{"textContent":_vm._s(item.name)}}),(false && item.description)?_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.description)}}):_vm._e()],1)],1):_vm._e()]}),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(
                    section.items.length && index != _vm.dynamiclegendSections - 1
                  ),expression:"\n                    section.items.length && index != dynamiclegendSections - 1\n                  "}],key:("legend_devider_" + key),staticClass:"my-1 mt-4"})]:_vm._e()]})],2)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }