const getters = {};
const mutations = {
  SET_maptivateSetupObject(state, newValue) {
    state.maptivateSetupObject = newValue;
  }
};

const actions = {
  async global_updateMaptivateSetupObject({ commit }, wpData) {
    commit("SET_maptivateSetupObject", wpData.maptivateSetupObject);
  }
};
const state = {
  maptivateSetupObject: {},
  appSettings: {
    map: {
      accessToken:
        "pk.eyJ1IjoibWFwdGl2YXRlbm9ibGVwYXJrIiwiYSI6ImNsdmc5Y2dmdzA5enkybHB1cHUxeGR2OXAifQ.81vbFetspH5VSulMwHpHKw", //maptivate key;
      options: {
        style: "mapbox://styles/maptivatenoblepark/clwob1qp700w801poeplr78d5", //main style
        center: [145.1734, -37.965],
        maxZoom: 21.8,
        minZoom: 11,
        pitch: 27,
        maxPitch: 27,
        minPitch: 27,
        bearing: 0,
        //bearing: 0,
        zoom: 12
      },
      boundsx: [
        [144.85036143131515, -37.8533280298009], // Southwest coordinates
        [145.0615299795112, -37.712797163701886] // Northeast coordinates
      ],
      defaultSetup: {
        center: [145.0, -37.965],
        zoom: 16,
        zoomTab: 16,
        zoomMob: 16,
        pitch: 27,
        bearing: -8
        //bearing: 0
      },
      flyToBounds: [
        [145.17282571240543, -37.96499164924927], // Southwest coordinates
        [145.1770943174336, -37.96900781407389] // Northeast coordinates
      ],
      disableRotationAndBearing: true //fix lock pan ;
    },

    wpEditSite:
      "https://cms01.maptivateapp.com.au/nobleparkdandenong/wp-admin/post.php?post=",

    onMoreInfoLoadOnMobileExpanText: true,

    pointsHaveDirections: false,
    markerIsPointer: false,
    showSiteNumbers: false,
    showSiteNumbers_Labels: true,
    showSiteNumbers_ClusterLabels: false,
    showQrCode: false,
    minZoom: { main: 1, secondary: 1, points: 1 },

    //nonClusteredDataSouces: [],
    //clusteredDataSouces: ["places", "signage"],

    useMapClustering: true,

    layersUnder: {
      art: null, //"melbzoo-labels (1)",
      places: null, //"melbzoo-labels (1)",
      signage: null, //"melbzoo-labels (1)",
      lines: false,
      fill: false,
      arrows: false
    },
    defaultTooltipLabelField: "mv_tooltip",

    markerImageAssetOrderPriority: {
      //list by section slug name,
      artwork_section: {
        catTaxonomy: "artwork_section",
        name: "artwork",
        behaviour: "normal", //normal, cluster,
        skip: [],
        priority: ["priority-buildings", "train"],
        delay: []
      },
      signage_section: {
        catTaxonomy: "signage_section",
        name: "signage",
        behaviour: "normal", //normal, cluster,
        skip: [""],
        priority: [""],
        delay: ["sensory"]
      },
      markersection: {
        catTaxonomy: "markersection",
        name: "marker",
        behaviour: "cluster",
        load: ["fighting_extinction_species"]
      }
    },
    layerDefaults: {
      signage: {
        labelField: "mv_label",

        scaling: {
          minZoom: 8,
          opacityMinZoom: 5,
          opacityMaxZoom: 6,

          clusterOpacityMinZoom: 5,
          clusterOpacityMaxZoom: 6,
          unclusterScale: 0.7
        },

        haveToolTip: true,
        showLabels: false,
        styles: {
          unclustered_paint: { "circle-color": "#00dcff" },
          clustered_paint: { "circle-color": "#c08e60" }
        },
        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterSetup: { cluster: true, clusterMaxZoom: 1, clusterRadius: 30 },
        filter: {
          unclustered_layer: ["!", ["has", "point_count"]],
          clustered_layer: ["has", "point_count"]
        }
      },
      places: {
        labelField: "SiteMarkerNumberLabelAct",

        useCircle: true,
        scaling: {
          minZoom: 7,
          opacityMinZoom: 10,
          opacityMaxZoom: 16.2,

          clusterOpacityMinZoom: 10,
          clusterOpacityMaxZoom: 16.2,
          unclusterScale: 1
        },

        hasPopup: true,
        showLabels: true,
        //labelsField: "index",
        hoverState: true,
        haveToolTip: true,
        styles: {
          //unclustered_paint: { "circle-color": "#ff0000" },
          //clustered_paint: { "circle-color": "#354d64" },

          unclustered_icon_size: {
            stops: [
              [16, (7.5 * 2) / 35],
              [19, (15 * 2) / 35]
            ]
          },
          unclustered_radius: {
            stops: [
              [10, 1],
              [14, 3],
              [16, 10],
              [20, 20]
            ]
          },
          clustered_radius: {
            stops: [
              [10, 1],
              [14, 3],
              [16, 12],
              [20, 23]
            ]
          },
          unclustered_textSize: {
            stops: [
              [14, 1],
              [16, 12],
              [20, 23]
            ]
          }
          /*
          clustered_textSize: {
            stops: [
              [16, 12],
              [20, 23]
            ]
          },
*/
        },

        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterLabel: true,
        clusterSetup: {
          cluster: true,
          clusterMaxZoom: 18,
          clusterRadius: 30,

          clusterProperties: {}
        },
        filter: {
          unclustered_layer: ["!", ["has", "point_count"]],
          unclustered_layer_symbol: [
            "all",
            ["!has", "point_count"],
            ["==", "render_icons", true]
          ],
          //unclustered_layer: ["in", "section_slug","points-of-interest"],

          clustered_layer: ["has", "point_count"]
        }
      }
    },

    socialIcon: {
      instagram: {
        icon: "mdi-instagram",
        color: "red lighten-3"
      },
      facebook: {
        icon: "mdi-facebook",
        color: "indigo"
      },
      twitter: {
        icon: "mdi-twitter",
        color: "#26c6da"
      },
      youtube: {
        icon: "mdi-youtube",
        color: "red"
      },
      tiktok: {
        icon: "mdi-alarm-multiple ",
        color: "black"
      },
      linkedin: {
        icon: "mdi-linkedin",
        color: "cyan darken-1"
      },
      pinterest: {
        icon: "mdi-pinterest",
        color: "pink"
      },
      generic: {
        icon: "mdi-link-variant",
        color: "green"
      }
    },

    siteListSettings: {
      itemUseCircle: true,
      sectionUseCircle: true
    },

    validArSites: {
      "14": true,
      "23": true,
      "28B": true
    },
    zoomToOnSelect: 18,

    allowSkipWelcomeDialog: false,

    autoOpenMoreInfo: true,
    drawWidth: 450,
    mobDrawWidth: 320,

    popupButtonWidth: "269px",
    drawButtonWidth: "435px",

    mediaButtonColor: "buttonblue", //a6caa6
    filterLegendButtonColor: "accent", //a6caa6

    appButtonColor: "buttonblue",
    dialogActionButtonColor: "buttonblue",
    logoTextColor: "primary",
    filterButtonColor: "primary",
    mainMenuBarColor: "primary",
    menuBarColor: "accent",
    audioPlayColor: "white",

    headingTextColor: "font-weight-regular text--darken-1",
    styles: {
      //oldMarkerColours: ["#003953", "#607d8b", "#f28cb1", "#d8db76"],
      markerColours: ["#582110", "#f4afcf", "#582110", "#961a1d"],
      //oldmarkerText: "#f6e9d8",
      markerTextLabel: "white",
      markerText: "black",
      markerTextBg: "black"
    },

    tmsLayerSetup: {
      hasLayer: true,
      sourceId: "titleLayerSource",
      sourceOptions: {
        type: "raster",
        tiles: [
          "https://storage.maptivateapp.com.au/cms/cms01/nbp/tms_05/{z}/{x}/{y}.png"
        ],
        //tileSize: 256,
        tileSize: 512,
        bounds: [
          145.170972173,
          -37.970886470675346,
          145.17856986183244,
          -37.962263416999996
        ], // Set bounds
        minzoom: 10,
        maxzoom: 22,
        scheme: "tms"
      },
      layerId: "titleLayer",
      layerOptions: {
        type: "raster",
        minzoom: 10,
        maxzoom: 22
      }
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
