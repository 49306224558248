const wpPull = require("./wpPull");
const cachePull = require("./cachePull");
//const patchData = require("./mods/patchData");

var useCache = false;
var useS3Cache = true; //if using cache, use this address;
var useS3Address = `https://maptivate-cms-01.s3.ap-southeast-2.amazonaws.com/sites01/4/cacheData.json`;

//using window.mvSource={mvs:"w",mvc:'s'}; for configs;

/*
in header add:
window.mvSource = {
  mvs: "c", // maptivate source, w = wordpress live, c = cache;
  mvc: "s", // maptivate cache location if used, s = s3, l = local/baked in   ;
  mvsl: "sites01/4" // maptivate s3 cache location 'sites01/4';
};

*/
/*
window.mvSource = {
  mvs: "c", // maptivate source, w = wordpress live, c = cache;
  mvc: "s", // maptivate cache location if used, s = s3, l = local/baked in   ;
  mvsl: "sites02" // maptivate s3 cache location 'sites01/4';
};


*/

//window.mvSource = null;

if (window.mvSource) {
  if (window.mvSource.mvs && window.mvSource.mvs == "c") {
    //use cache;
    useCache = true;
  }
  if (window.mvSource.mvc && window.mvSource.mvc == "l") {
    //use local cache not s3 as the cache;
    useS3Cache = false;
  }

  if (
    window.mvSource.mvsl &&
    window.mvSource.mvsl.length &&
    window?.mvSource?.mvb == "s"
  ) {
    //mvb = s3 bucket, s for storage.maptivateapp.com.au

    //speficy project address in S3.
    useS3Address = `https://storage.maptivateapp.com.au/cms/${window.mvSource.mvsl}/cacheData.json`;
  } else if (window.mvSource.mvsl && window.mvSource.mvsl.length) {
    //speficy project address in S3.
    useS3Address = `https://maptivate-cms-01.s3.ap-southeast-2.amazonaws.com/${window.mvSource.mvsl}/cacheData.json`;
  }
}

const state = {
  post: {},
  taxonomy: {},
  wpdata: {}
};

const filterDataSets = (output, options) => {
  /*
  const moveMarkes = false;
  //output.geoJson_artwork.features = [output.geoJson_artwork.features[0]];

  if (moveMarkes) {
    let items = JSON.parse(JSON.stringify(output.geoJson_artwork.features));


    let modArray = [];
    for (let i = -10; i < 10; i++) {
      for (let j = -10; j < 10; j++) {
        modArray.push({ i, j });
      }
    }

    let modYX = 0.002;

    modArray.forEach(modItem => {
      let { i, j } = modItem;

      items.forEach(point => {
        let pointClone = JSON.parse(JSON.stringify(point));
        let [x, y] = pointClone.geometry.coordinates;
        let xMod = parseFloat(x) + i * modYX;
        let yMod = parseFloat(y) + j * modYX;

        pointClone.geometry.coordinates = [xMod, yMod];

        //console.log("ping", pointClone);
        pointClone.id = Math.round(Math.random() * 1000000);
        pointClone.properties.id = pointClone.id;
        pointClone.properties.Latitude = xMod;
        pointClone.properties.Longitude = yMod;

        output.geoJson_artwork.features.push(pointClone);
      });
    });

  }

*/
  if (options?.isSpecialEnviroment === true) {
    try {
      output.geoJson_main_marker.features = output.geoJson_main_marker.features.filter(
        row => {
          return (
            row?.properties?.structuredData?.value?.M_C_YN_SHOW_IN_SPECIAL
              ?.value === "Yes"
          );
        }
      );
    } catch (error) {
      //error;
    }
  }
};

const getters = {};
const actions = {
  async wpData_initData({ commit }, options) {
    let { isSpecialEnviroment } = options;

    let output;

    if (useCache === true) {
      output = await cachePull.getData({
        useS3: useS3Cache,
        useS3Address,
        isSpecialEnviroment
      });
    } else {
      output = await wpPull.getData({ isSpecialEnviroment });
    }

    filterDataSets(output, { isSpecialEnviroment });

    //patchData.updateArtWorkOrderForNP(output);

    //output.geoJson_artwork.features.reverse();
    //console.log(output.geoJson_artwork.features.map(row=>row.properties.tempDist));

    //console.log("output");
    //console.log({output});

    /*

    let values = output.geoJson_main_marker.features.map(row => {
      let { properties } = row;
      let {
        Site_Marker_Number,
        Point_Name,
        Institute_Name_And_Point_Name,
        section_slug,
        list_section_title,
        list_section_slug
      } = properties;
      return {
        id: Site_Marker_Number,
        name: Point_Name,
        institute: list_section_title,
        state: section_slug,
        Institute_Name_And_Point_Name,
        initials: list_section_slug
      };
    });

    //console.log(values);
*/

    commit("SET_WP_DATA", output);
  }
};

const mutations = {
  SET_WP_DATA(state, newValue) {
    state.wpdata = newValue;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
