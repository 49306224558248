import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify, {
  iconfont: "md"
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#202020", //menu colors;buttons
        info: "#feefd6", //selected list items
        accent: "#303030", //side bar tabs
        success: "white", //header/footer text;
        buttonblue: "#3984cf"
        /*

        primary: "#3984cf", //menu colors;buttons
        info: "#feefd6", //selected list items
        accent: "6e9ccb", //side bar tabs
        success: "white" //header/footer text;

        secondary: "#1f3531",
        accent: "white", //link text
        error: "#1f3531", //item se
        info: "#51702d",
        success: "#061a35",
        warning: "#fcfbf1" //marker text
        */
      }
    }
  }
});
