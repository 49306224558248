<template>
  <div
    class="mvLegendWrapper"
    style="width:0;height:0;
    overflow: visible;"
    :class="drawer ? 'open' : 'close'"
  >
    <div class="mvLegend">
      <div class="mobileSet">
        <div class="mvLegendButtonWrapper" v-if="!showLegend">
          <v-btn
            title="Show Legend"
            z-depth="0"
            depressed
            notsmall
            class="ml-2 pa-0"
            :color="!showLegend ? appSettings.filterLegendButtonColor : 'white'"
            @click="showLegend = !showLegend"
          >
            <v-icon v-if="showLegend">mdi-close-circle </v-icon>
            <v-icon v-if="!showLegend" color="white">mdi-map-legend </v-icon>
          </v-btn>
        </div>

        <div
          v-show="showLegend"
          class="legendListActWrapper"
          style="border-radius: 8px;overflow: hidden;"
        >
          <v-card
            :max-width="$vuetify.breakpoint.smAndDown ? 300 : 300"
            class="mx-auto"
          >
            <v-toolbar color="" dense height="35">
              <v-toolbar-title>Legend</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="showLegend = false" title="Close Legend">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-list dense class="legendListAct">
              <template v-for="(section, key, index) in dynamiclegendOld">
                <template
                  v-if="
                    key && hideByLegendKey && !hideByLegendKey.includes(key)
                  "
                >
                  <v-subheader
                    v-show="section.items.length"
                    class="text-subtitle-1 font-weight-bold ml-11 mb-1 pt-1"
                    :key="`legend_heading_${key}`"
                  >
                    {{ section.title }}

                    <v-btn
                      class="ml-3"
                      v-show="
                        showLayerButtonForSlugIfHidden.includes(key) &&
                          !layers.includes(key)
                      "
                      x-small
                      text
                      outlined
                      depressed
                      @click="showLayer(key)"
                      color="error"
                    >
                      show
                    </v-btn>
                  </v-subheader>

                  <template v-for="(item, i) in section.items">
                    <v-list-item
                      v-if="
                        item &&
                          (!item.section_slug ||
                            !hideSectionSlugs.includes(item.section_slug))
                      "
                      :key="`legend_list_${key}_item_${i}`"
                    >
                      <v-list-item-avatar :tile="item.tile === true" size="30">
                        <template
                          v-if="
                            item &&
                              item.taxonomy != 'markersection' &&
                              item.featured_image_obj &&
                              item.featured_image_obj.thumb
                          "
                        >
                          <img :src="`${item.featured_image_obj.thumb}`" />
                        </template>

                        <template v-else>
                          <v-avatar
                            size="30"
                            :color="item.color"
                            v-bind:style="item.style"
                          ></v-avatar>
                        </template>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="font-weight-regular"
                          v-text="item.name"
                        ></v-list-item-title>

                        <v-list-item-subtitle
                          v-if="false && item.description"
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-divider
                    v-show="
                      section.items.length && index != dynamiclegendSections - 1
                    "
                    class="my-1 mt-4"
                    :key="`legend_devider_${key}`"
                  ></v-divider>
                </template>
              </template>
            </v-list>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { eventBus } from "../../main";
//import LegendItem from "./LegendItem.vue";

import { mapState } from "vuex";
//import TableDataView from '../info/TableDataView.vue';
export default {
  name: "mapLegend",
  components: {
    //TableDataView
    //LegendItem
  },

  props: {
    drawer: Boolean
  },

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      categoryLookup: state => state.markers.categoryLookup,
      geoJson_signage: state => state.markers.geoJson_signage,
      layers: state => state.filters.layers
    }),
    iconSize() {
      const size = { xs: "x-small", sm: "x-small", lg: "small", xl: "small" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },

    dynamiclegendOld() {
      if (this.geoJson_signage?.data?.features) {
        let legend = {
          /*
          fighting_extinction_species: {
            title: "Manual section 1",
            items: [
              {
                section_slug: "cust",
                tile: true,
                key: "FE_Priority_Species",
                title: "Item 1",
                name: "Item 1",
                featured_image_obj: {
                  thumb: "/favicon.png",
                  title: "Item 1"
                },
                color: "#236529"
              },
              {
                section_slug: "cust",
                tile: true,
                key: "global_conservation",
                title: "Item 2",
                name: "Item 2",
                featured_image_obj: {
                  thumb: "/favicon.png",
                  title: "Item 2"
                },
                color: "#236529"
              }
            ]
          },

          keepertalks: {
            title: "Manual section 2",
            items: [
              {
                section_slug: "cust",
                tile: true,
                key: "custKeeperTalkes",
                title: "Item 3",
                name: "Item 3",
                featured_image_obj: {
                  thumb: "/favicon.png",
                  title: "FE species white"
                },
                color: "#236529"
              }
            ]
          },
*/
          main_marker: {
            title: "Layers",
            items: []
          }
        };

        for (const key in this.categoryLookup) {
          if (!this.dontImportSlugs.includes(key)) {
            let row = JSON.parse(JSON.stringify(this.categoryLookup[key]));
            row.tile = true;
            if (row.section_type === "main_marker") {
              legend.main_marker.items.push(row);
            } else if (row.section_type != "artwork") {
              delete row.featured_image_obj;
              //legend.other.items.push(row);
              legend[key] = { title: row.title, items: [] };
            }
          }
        }

        let lookup = {};
        this.geoJson_signage.data.features.forEach(row => {
          let tempRow = JSON.parse(JSON.stringify(row.properties));

          var title = tempRow.Point_Name;
          let words = title
            //.replace("Ramp", "Ramp ")
            //.replace("Evac", "Evacuation Point ")
            .trim()
            .split(" ");
          if (!isNaN(words[words.length - 1].trim())) {
            words.pop();
          }

          title = words.join(" ");
          lookup[title] = {
            featured_image_obj: tempRow.icon_obj,
            name: title,
            tile: true,
            section: tempRow.section_slug ? tempRow.section_slug : "other"
          };
        });

        _.values(lookup).forEach(row => {
          if (row.section) {
            if (!this.dontImportSlugs.includes(row.section)) {
              legend[row.section].items.push(row);
            }
          }
        });

        this.staticLegendItems.forEach(row => {
          legend[row.section].items.push(row);
        });
        /*
        legend.transport = {
          title: "Transport",
          items: [
            {
              featured_image_obj: {
                id: 178,
                title: "Asset 2",
                thumb: "/assets/icons/tram.png",
                section_slug: "transport",
                typeSlug: "signage_section",

                scale_factor: 2
              },
              name: "Tram #58 West Coburg",
              tile: true,
              section: "transport"
            },
            {
              featured_image_obj: {
                id: 179,
                title: "Asset 3",
                thumb: "/assets/icons/train.png",

                section_slug: "transport",
                typeSlug: "signage_section",

                scale_factor: 2
              },
              name: "Royal Park Train Station",
              tile: true,
              section: "transport"
            }
          ]
        };
*/

        delete legend.signage;

        return { ...legend };
      } else {
        return {};
      }
    },

    dynamiclegendSections() {
      return _.size(this.dynamiclegendOld);
    }
  },
  data: () => ({
    dontImportSlugs: ["keepertalks"],
    hideSectionSlugs: ["fighting_extinction_species"],
    hidePostType: [],
    hideByLegendKey: ["xkeepertalks"],
    staticLegendItems: [],
    staticLegendSectionsxx: {},
    staticLegendSections: {},
    readOnlyButtonValues: [],
    showLegend: false,
    showLayerButtonForSlugIfHidden: [
      "xxxfighting_extinction_species",
      "xxretail_and_eating",
      "xxkeepertalks",
      "xxsensory"
    ]
  }),
  methods: {
    remoteClose() {
      this.showLegend = false;
    },
    showLayer(section_slug) {
      this.$store.dispatch("filters_enableLayer", section_slug);
    }
  },
  created() {
    eventBus.$on("remote-ui-legend-close", this.remoteClose);
  },
  beforeDestroy() {
    eventBus.$off("remote-ui-legend-close", this.remoteClose);
  },

  watch: {
    showLegend: function(newValue) {
      if (newValue === true) {
        window.mvAnalyticEvent("ui", "mapLegendOpen", "open", false);
        eventBus.$emit("remote-ui-layercontrol-close");
      }
    }
  }
};
</script>

<style scoped>
.v-list-item__avatar.custMarker {
  overflow: visible;
}
.mobileSet {
  transition: all 0.5s;
  position: absolute;
  border-radius: 2px;
}

.mvLegendWrapper {
  z-index: 1000;
  overflow: visible;
}
.mvLegend {
  background: red;
  width: 0;
  height: 0;
  position: relative;
  bottom: 26px;
  right: 440px;

  transition: all 0.2s;
}
.mvLegendWrapper.open .mvLegend {
  right: 440px;
}

.mvLegendWrapper.close .mvLegend {
  right: -10px;

  bottom: 42px;
}

.mvLegendButtonWrapper,
.legendListActWrapper .v-list--dense .v-list-item .v-list-item__title {
  font-size: 0.9125rem;
  line-height: 0.95rem;
}

.mvLegendButtonWrapper,
.legendListActWrapper .v-list-item__avatar:first-child {
  //margin-right: 16px;
}

.legendListAct {
  max-height: calc(100vh - 200px);
  xxmax-height: calc(var(--doc-height) - 100px);
  overflow-y: auto;
}

.mvLegendButtonWrapper,
.legendListActWrapper {
  position: absolute;
  bottom: 0;
  right: 0;
}
.legendListActWrapper {
  border: 1px solid #d8d8d8;
  width: 300px;
}

.v-list-item__title,
.v-list-item__subtitle {
  flex: 1 1 100%;
  overflow: visible;
  text-overflow: unset;
  white-space: unset;
}

.v-list--dense .v-subheader {
  height: auto;
}

.v-list--dense .v-list-item {
  min-height: 40px;
}

.v-list--dense .v-list-item .v-list-item__content {
  padding: 4px 0;
}
.v-list--dense .v-list-item__avatar {
  margin-bottom: 0px;
  margin-top: 1px;
}

header.v-toolbar {
  box-shadow: none !important;
  border-bottom: 1px #d2d2d2 solid;
}

@media only screen and (max-width: 959px) {
  div.mvLegendWrapper.open div.mvLegend,
  div.mvLegendWrapper.close div.mvLegend {
    position: absolute;
    right: 6px;
    bottom: 40px;
  }
}

.legendListAct {
  //height: calc(100vh - 210px) !important;
}

@media only screen and (max-width: 1263px) {
  .legendListAct {
    //max-height: calc(100vh - 270px) !important;

    ///height: calc(100vh - 150px) !important;
  }
}

@media only screen and (max-width: 959px) {
  .legendListAct {
    //max-height: calc(100vh - 270px) !important;

    //height: calc(100vh - 150px) !important;
  }
}
</style>
