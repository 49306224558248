//import _, { initial } from "lodash";

import { mapState } from "vuex";

//import { eventBus } from "../../../main";

export default {
  data: () => ({
    //layerState: false
    currentPopup: null
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  watch: {},
  methods: {
    addTmsLayer(map) {
      if (this.appSettings?.tmsLayerSetup?.hasLayer) {
        let {
          sourceId,
          sourceOptions,
          layerId,
          layerOptions
        } = this.appSettings.tmsLayerSetup;

        map.addSource(sourceId, {
          ...sourceOptions
        });

        map.addLayer({
          id: layerId,
          source: sourceId,
          ...layerOptions
        });
      }
    }
  },
  created() {},
  destroyed() {},
  mounted() {}
};
